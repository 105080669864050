
import configs from '../config/config.json';
const checkIsLocal = configs.serverURL.includes('localhost');

const SERVICES_LOCAL_ENDPOINTS = {
    AUTH: '10001', // API's de autenticação
    PARTNER: '/ptr', // API's de parceiro
    COMPANY: '/cpy', // API's de empresa
    CAMPAIGN: '3004', // API's de campanha
    USER: '3000', // API's de usuário 
    FILES: '3004/files', // Mesmo apontamento de campanha em localhost para files
}
const SERVICES_ENDPOINTS = {
    AUTH: '/aut', // API's de autenticação
    PARTNER: '/ptr', // API's de parceiro
    COMPANY: '/cpy', // API's de empresa
    CAMPAIGN: '/cmp', // API's de campanha
    USER: '/usr', // API's de usuário 
    FILES: '/files', // Tanto para H1 quanto para prod
}
export const CONSTANTS = {
    ALIAS: {
        PROGRAM_CODE: (aliasProgramCode: string) => checkIsLocal ? 'programanovo' : aliasProgramCode, // 'nboneee',
        COMPANY: (aliasCompany: string) => checkIsLocal ? 'nbone' : aliasCompany,
        CAMPAIGN: (campaignPathname: string) => campaignPathname ? campaignPathname : '',
    },
    PROGRAM_CODE: "nboneee",
    CAMPAIGN: {
        LOCALHOST_DOMAIN: "localhost",
        SUBDOMAIN: "nbone",
        CAMPAIGN_NAME: "firstcampaign"
    },
    SERVER_DOMAINS: {
        LOCAL: 'http://localhost:',
        H1: '.h1.campanhasonline.com.br',
        D1: '.d1.campanhasonline.com.br',
        P1: '.campanhasonline.com.br',
    },
    SERVICES_APIS_DOMAINS: {
        DOMAINS: {
            LOCAL: 'http://localhost:',
            H1: 'https://api.h1.campanhasonline.com.br',
            D1: 'https://api.d1.campanhasonline.com.br',
            P1: 'https://api.campanhasonline.com.br',
        },
        ENDPOINTS: {
            LOCAL: SERVICES_LOCAL_ENDPOINTS,
            H1: SERVICES_ENDPOINTS,
            D1: SERVICES_ENDPOINTS,
            P1: SERVICES_ENDPOINTS,
        },
        localEndpoints: {
            auth: '10001', // API's de autenticação
            partner: '/ptr', // API's de parceiro
            company: '/cpy', // API's de empresa
            campaign: '3004', // API's de campanha
            user: '3000', // API's de usuário 
            files: '3004/files', // Mesmo apontamento de campanha em localhost para files
        },
        endpoints: {
            auth: '/aut', // API's de autenticação
            partner: '/ptr', // API's de parceiro
            company: '/cpy', // API's de empresa
            campaign: '/cmp', // API's de campanha
            user: '/usr', // API's de usuário 
            files: '/files', // Tanto para H1 quanto para prod
        }
    }
}