/* eslint-disable react-hooks/exhaustive-deps */
import { Outlet, useParams } from "react-router-dom"
import React from "react";
import { Helmet } from 'react-helmet';
import { location } from '@utils';
import { loadGlobalServices } from '@load/global-services';
import { useTheme } from "../../../hooks/theming";

export const DefaultLayout: React.FC = () => {
    const { campaignPath } = useParams();
    const { hotsite, campaign } = useTheme();
    const globalServices = loadGlobalServices();

    React.useEffect(() => {
        globalServices.theme.loadHotsiteTheme(['texts', 'styles']);
        globalServices.theme.loadCampaignTheme(['texts', 'styles']);
    }, []);

    return (
        <div className="wrapper">
            <Helmet>
                <title>{campaignPath ? 'Campanha' : 'Programa'}</title>
                <link rel="apple-touch-icon" href={campaignPath ?
                    (campaign.theme.texts.isLoaded ? location.filesPath(campaign.theme.texts.data.header.headerLogo) : '') :
                    (hotsite.theme.texts.isLoaded ? location.filesPath(hotsite.theme.texts.data.header.headerLogo) : '')} />
                <link rel="icon" href={campaignPath ?
                    (campaign.theme.texts.isLoaded ? location.filesPath(campaign.theme.texts.data.header.headerLogo) : '') :
                    (hotsite.theme.texts.isLoaded ? location.filesPath(hotsite.theme.texts.data.header.headerLogo) : '')} />
            </Helmet>
            <Outlet />
        </div>
    )
}